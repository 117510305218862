<!-- Name -->
<script lang="ts">
export default {
  name: 'brand-view'
}
</script>

<script lang="ts" setup>

// definePageMeta({
//      middleware: "font-change"
//   });
import { onMounted} from 'vue';
import { useBrandsStore } from '~/store/brands';
import { useSpinnerStore } from '~/store/spinner';
import { useVehiculosVoStore } from '~/store/vehiculosvo';
import { useBrandsInfoStore } from '~/store/brandsInfo'; 
import { usePopupsStore } from '~/store/popups';


const layout = "brand";
const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const { $api } = useNuxtApp();
const router = useRouter();
const route = useRoute();
const { setFont } = useFonts(); 
const marca= ref(route.params.marca);
const {isMobileSize}=useDeviceFunctions ();
const {showSpinner, hideSpinner} = useSpinnerStore()
const {saveBrand, brandVehicles } = useVehiculosVoStore();
const { gamas: storedGamas, setGamas, setSelectedBrand } = useBrandsStore();
const brandsInfoStore= useBrandsInfoStore();
const brand = brandsInfoStore.getBrand(marca.value);
const popupsStore = usePopupsStore();
const { cyclesBrands } = storeToRefs(brandsInfoStore);


const customInfo= ref(await brandsInfoStore.getBrandCustomisation(marca.value));

const gamas = ref([]);
const vo = ref([]);
const news=ref([]);
const promos=ref([]);
const animationStatus = ref(false);

const options = {
  type   : 'loop',
  perPage: 3,
  breakpoints: {
    992: {
        perPage: 1
    }
  },
  gap        : '26px',
  focus  : 'center',
  pagination: false,
  isNavigation: true,
  arrows: true,
  classes: {
    arrows: 'splide__arrows your-class-arrows',
		arrow : 'splide__arrow your-class-arrow',
		prev  : 'splide__arrow--prev gama-slider-prev',
		next  : 'splide__arrow--next gama-slider-next',
  }
};


//computeds
const isMarcosCycles = computed(()=> {
    return marca.value == 'marcos-cycles';
});

const brandSelected=computed(()=>{
    return route.params.marca !=='marcos-cycles' ? route.params.marca: ( cyclesBrands.value.includes('Vespa') ? 'vespa': cyclesBrands.value[0].toLowerCase());
});


setSelectedBrand(brandSelected.value);

//functions

const startAnimation = (status) => {
    animationStatus.value = status
};



const getGamas = async () => {
  if(!gamas.value.length){
    showSpinner();
      try {
        const { data, error } = await $api.gamas.getGamas(brandSelected.value ,{
            pick:['payload']
        });
        
        gamas.value = await data.value?.payload as unknown;
        setGamas(gamas.value)
          
    } catch (error) {
        //TODO: Controlar error
    } 
    finally{
        hideSpinner();
    }
  }
  else{
    gamas.value = storedGamas.value
  }
};

getGamas();

try {
    if(!isMarcosCycles.value){
        const formData = new FormData();
        formData.append('marcas', brand?.nombre);
        const {data, error} = await $api.vehiculosVo.index(formData, {
            pick:['payload']
        });
        vo.value = await data.value?.payload.data as unknown;
        saveBrand(vo.value)

    }
    else{
        const formData = new FormData();
        const {data, error} = await $api.motosVo.index(formData, {
            pick:['payload']
        });

        vo.value = await data.value?.payload.data as unknown;

    }

    if (error.value) {
        throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
    }
} catch (error) {
    //TODO: Controlar error
}

//promociones
const fetchDataPromos = async () => {
    showSpinner();
    const formData = new FormData();
    formData.append('slug_marca', marca.value);
    formData.append('category', 'vn');

  const {
      data: promosData,
      pending: promosPending,
      error:promosError
  } = await $api.promos.getPromos(formData,{
      pick: ['payload']
      }); 
  
   hideSpinner();

  if (promosError.value) {
    throw createError({ statusCode: 500, statusMessage: promosError.value?.data?.message || promosError.value.message });
  }
  
  promos.value =  promosData.value.payload.data;
};

await fetchDataPromos();

//noticias
const fetchDataNews = async () => {
    showSpinner();
    const formData = new FormData();
  const {
      data: newsData,
      pending: newsPending,
      error:newsError
  } = await $api.news.getBrandNews(formData,marca.value,{
      pick: ['payload']
      }); 
    hideSpinner();


  if (newsError.value) {
    throw createError({ statusCode: 500, statusMessage: newsError.value?.data?.message || newsError.value.message })
  }
  news.value=newsData.value.payload.data;
};

await fetchDataNews();

const animation = () => {

    const scrollTrigger = {
        trigger: '.banner-pin',
        endTrigger: '.banner-pin',
        toggleActions: 'play reverse play reverse',
        start: '2% top',
        end: `+=900`,
        pin: true,
        // pinSpacing: false,
        // markers: true,
        scrub: true,
    }

    let tl = gsap.timeline({ scrollTrigger })
    tl.to('.pin-round', {
        scale: 10, duration: 7
    }).to('.brand-view__header', {
        opacity: 0,
    }, "-=4s")
}


const routeToVo = !isMarcosCycles.value ? `/${route.params.marca}/coches-ocasion/` : `/${route.params.marca}/motos-ocasion/`;


onMounted(() => {
    setFont()
    animation()
    if(!popupsStore.popupPagesVisited.includes(route.params.marca)){
        popupsStore.showPopup(route.params.marca,'home'); // Carga el popup inicial
    }
});

useMetadatos();

</script>

<template lang="pug">
NuxtLayout(:name="layout")
    .brand-view
        //- .brand-view__pin(id="banner-pin")
        .banner-pin
            article.brand-view__header(id="banner-top")
                .container-fluid 
                    .row 
                        .col-12.px-0
                            .brand-view__header-content(:class="{'active': animationStatus}", id="brand-view-header")
                                img.img-fluid.d-none.d-lg-block(:src="customInfo.imagen_home" alt="")
                                img.img-fluid.d-lg-none(:src="customInfo.imagen_home_mobile ? customInfo.imagen_home_mobile : customInfo.imagen_home" alt="")
                                .container.container-big 
                                    .title-container
                                        p.h1 {{customInfo.titulo_home}}
                                        p {{customInfo.texto_home}} 
                                        nuxt-link.btn-brand.btn-brand--home(:to="customInfo.cta_url_home") {{customInfo.cta_home}}
                .pin-round
            article.brand-view__sub-header(id="banner-bottom")
                .container-fluid 
                    .row 
                        .col-12.px-0
                            .brand-view__sub-header-content(:class="{'active': animationStatus}", id="brand-view-subheader")
                                //- img.img-fluid(src="~assets/images/brand-peugeot-delete.jpg" alt="")
                                //- span.icon-arrow-right-long(@click="startAnimation(false)")
                                //- a(href="#brand-view-header")
                                //-     span.icon-arrow-right-long
                                .container.container-big 
                                    span.brand-logo(:class="`icon-${route.params.marca}`")
                                    //- img.img-fluid(src="~assets/images/brand-bg-home-peugeot.png", alt="")
                                    .brand-text(:class="route.params.marca")
                                        h1 {{customInfo.titulo_intro}}
                                        small {{customInfo.texto_intro}}
                            
        //- .brand-view__pin(id="banner-pin")
        article.section.brand-view__gama
            .container 
                .row
                    .col.text-center.mb-1.mb-lg-5 
                        h2.headline--4 {{route.params.marca !== 'marcos-cycles' ? 'Vehículos' : 'Motos'}} gama {{ brandsInfoStore.getBrandNameBySlug(route.params.marca) }}
                        nuxt-link.btn-brand-tertiary.btn-brand-tertiary--secondary(:to="`/${route.params.marca}/gama/`") 
                            span.text-uppercase.text-decoration-underline.line-underline-adjust VER TODA LA GAMA
            .brand-view__gama-slider
                .container-fluid 
                    splide(:options="options")
                        splide-slide(v-for="(gama, index) in gamas", :key="index")
                            .gama-slider-item.text-center
                                p.h4.text--brand-primary.text-center.mb-0.mb-lg-2 {{ gama.modelo }}
                                img.img-fluid(:src="gama.thumbnail" :alt="`${gama.marca} ${gama.modelo}`")
                                .gama-slider-item-price 
                                    p.h5.text--brand-primary.mb-4(v-if="gama.precio && gama.precio>0") DESDE {{priceFormat(gama.precio)}}€
                                    p.h5.text--brand-primary.mb-4(v-else) CONSULTAR PRECIO
                                    nuxt-link.btn-brand.btn-brand--cta.btn-brand--medium(:to="{path: `/${route.params.marca}/gama/${gama.slug_modelo}/`, query: {type: gama.tipo_vehiculo}}") 
                                        span DESCUBRE ESTE MODELO
                        

        article.section.brand-view__vo(v-if="!!vo")
            .container 
                .row 
                    .col.text-center.mb-5 
                        h2.headline--4(v-if="!isMarcosCycles")  Vehículos de ocasión {{ brandsInfoStore.getBrandNameBySlug(route.params.marca) }} MARCOS AUTOMOCIÓN
                        h2.headline--4(v-else) Motos de Ocasión {{ brandsInfoStore.getBrandNameBySlug(route.params.marca) }}
            .container
                .row 
                    .col-12.col-md-6.col-lg-4.col-xl-3.mb-4(v-for="(item, index) in vo.slice(0, 4)" :key="index") 
                        UiBrandAppBrandCardVehicle(
                            :slug="`${route.params.marca}/coches-ocasion/${item.slug}`",
                            :vehicle="item"
                            detailType="vo"
                            type="vo"
                            v-if="!isMarcosCycles"
                            )
                        UiBrandAppBrandCardVehicle(
                            :slug="`${route.params.marca}/motos-ocasion/${item.slug}`",
                            :vehicle="item"
                            detailType="moto-vo"
                            type="moto-vo"
                            v-else
                            )
            .container 
                .row 
                    .col-12.text-center.mt-5
                        nuxt-link.btn-brand.btn-brand--cta.btn-brand--medium(:to="routeToVo")
                            span VER TODOS LOS VEHÍCULOS
        article.section.brand-view__promo(v-show="promos.length > 0 && customInfo.secciones_opcionales && customInfo.secciones_opcionales.promociones_vn")
            .container 
                .row 
                    .col.text-center.mb-5 
                        h2.headline--4 Promociones vehículos nuevos {{brandVariable(brandsInfoStore.getBrandNameBySlug(route.params.marca), route.params.marca)}} 
            .container
                .row 
                    .col-12.col-md-6.col-lg-4.col-xl-4.mb-4(v-for="(item, index) in promos.slice(0,3)" :key="index", v-if="promos.length") 
                        nuxt-link(:to=" item.enlace_externo ? item.url_externa : `/${marca}/promociones-marcas/${item.slug}/`" :target="item.enlace_externo ? '_blank' : '_self'")
                            ClientOnly
                                UiBrandAppBrandCardRegular(
                                :img="item.thumbnail",
                                :titulo="item.titulo",
                                :resumen="item.resumen"
                                )
            .container 
                .row 
                    .col-12.text-center.mt-5
                        nuxt-link.btn-brand.btn-brand--cta.btn-brand--medium(:to="`/${marca}/promociones-marcas/`")
                            span VER TODAS LAS PROMOCIONES
        article.section.brand-view__news(v-show="news.length > 0 && customInfo.secciones_opcionales && customInfo.secciones_opcionales.actualidad")
            .container 
                .row 
                    .col.text-center.mb-5 
                        h2.headline--4 Últimas noticias
                        nuxt-link.btn-brand-tertiary.btn-brand-tertiary--secondary(:to="`/${marca}/noticias/`") 
                            span.text-uppercase.text-decoration-underline.line-underline-adjust VER TODAS
            .container
                .row 
                    .col-12.col-md-6.col-lg-4.col-xl-4.mb-4(v-for="(item, index) in news.slice(0,3)" :key="index") 
                        UiBrandAppBrandCardNews(
                            :contentClass="'home'",
                            :img="item.thumbnail", 
                            :slug="item.slug",
                            :titulo="item.titulo",
                            :marca="marca"
                        )
        article.section.brand-view__services(v-if="customInfo.secciones_opcionales && (customInfo.secciones_opcionales.promociones_vn || customInfo.secciones_opcionales.empresas || customInfo.secciones_opcionales.seguros)")
            .container 
                .row 
                    .col.text-center.mb-5.text--brand-primary 
                        h2.headline--4 Servicios
                        p En {{brandVariable(brandsInfoStore.getBrandNameBySlug(route.params.marca),route.params.marca)}} disponemos de servicios especializados para tu comodidad
            .container
                .row 
                    .col-12.col-md-6.col-lg-4.col-xl-4.mb-4(v-if="customInfo.secciones_opcionales.promociones_vn")
                        .services-card 
                            .services-card__header 
                                img.img-fluid(:src="brand.imagen_servicio_1")
                                .services-card__header-title.text-center
                                    h3 Posventa
                            .services-card__footer.text-center 
                                p En {{brandVariable(brandsInfoStore.getBrandNameBySlug(route.params.marca),route.params.marca)}}, pensamos en ti y en lo mejor para tu bolsillo.
                                nuxt-link(:to="`/${route.params.marca}/promociones-posventa/`").btn-brand.btn-brand--cta
                                    span IR AL SERVICIO
                    .col-12.col-md-6.col-lg-4.col-xl-4.mb-4(v-if="customInfo.secciones_opcionales.empresas")
                        .services-card 
                            .services-card__header 
                                img.img-fluid(:src="brand.imagen_servicio_2")
                                .services-card__header-title.text-center
                                    h3 SERVICIOS A EMPRESAS
                            .services-card__footer.text-center 
                                p Descubre las mejores ofertas para empresas en {{brandVariable(brandsInfoStore.getBrandNameBySlug(route.params.marca),route.params.marca)}}.
                                nuxt-link(:to="`/${route.params.marca}/empresas/`").btn-brand.btn-brand--cta
                                    span IR AL SERVICIO
                    .col-12.col-md-6.col-lg-4.col-xl-4.mb-4(v-if="customInfo.secciones_opcionales.seguros")
                        .services-card 
                            .services-card__header 
                                img.img-fluid(:src="brand.imagen_servicio_3")
                                .services-card__header-title.text-center
                                    h3 SEGUROS
                            .services-card__footer.text-center 
                                p Mantenerte a salvo a ti y a tu {{route.params.marca !== 'marcos-cycles' ? 'coche' : 'moto'}} es un deber para {{brandVariable(brandsInfoStore.getBrandNameBySlug(route.params.marca),route.params.marca)}}.
                                nuxt-link(:to="`/${route.params.marca}/seguros`").btn-brand.btn-brand--cta
                                    span IR AL SERVICIO
        article.section.brand-view__seo
            .container.my-5
                .row 
                    .col-12 
                        // TODO: Descomentar
                        //- UiAppModuleSeo(:slug="`index-/${route.params.marca}`")
            //- .container.my-5 
            //-     .row 
            //-         .col.text--brand-primary  
            //-             //- UiAppModuleSeo(:slug="`index-/${route.params.marca}`")
            //-             h2.headline--4 Contenido SEO
            //-             p Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
            //-     .row 
            //-         .col-md-6.text--brand-primary(v-for="(item, index) in 2" :key="index") 
            //-             img.img-fluid(src="~assets/images/brand-seo-delete.jpg")
            //-             p Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 

            //-             p Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
</template>

<style lang="scss">
@import 'index';
</style>